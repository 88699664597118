import React from "react";
import boatImage from "../media/3.png";

const LandingPage = () => {
    const handleSeeMore = () => {
        if (window.innerWidth < 640) {
            window.scrollTo({top: 4500, behavior: 'smooth'});
        } else {
            window.scrollTo({top: 3200, behavior: 'smooth'});
        }
    };
    const heroHeight = () => {
        if (window.innerHeight < 640) {
            return "2.75rem";
        } else {
            return "3.75rem";
        }
    }
    return (
        <div className="flex w-full relative z-10 items-center justify-center" style={{height: "90lvh", backgroundImage: `url(${boatImage})`, backgroundSize: "cover"}}>
            <div className="z-30 font-montserrat text-slate-900 flex-row text-left w-fit max-sm:w-full max-sm:text-center ml-10 max-sm:ml-0 translate-y-16"> 
                <h1 className="font-cinzel font-bold w-3/4 max-sm:hidden" style={{fontSize: heroHeight(), lineHeight: 1}}>RESTORATION DONE THE RIGHT WAY</h1>
                <p className="text-3xl font-bold mt-4 w-4/5 max-md:text-3xl max-sm:font-extrabold max-sm:w-full max-sm:px-10 max-sm:text-4xl">
                    - Twenty years experience<br/>
                    - More than 250 restorations<br/>
                    - 5200 Bottoms our specialty<br/>
                    - Mirror finishes
                </p>
                <button onClick={handleSeeMore} className="py-4 px-4 bg-slate-900 rounded text-white mt-10 text-2xl max-sm:px-8" style={{fontSize: "1.5rem", lineHeight: 1}}>See More...</button>
            </div>
            <div className="w-1/4 bg-transparent max-md:hidden"></div>
        </div>
    );
}

export default LandingPage;

// <div className="z-30 absolute font-montserrat text-slate-900 flex-row text-left max-lg:mt-12 object-fill m-auto w-1/2 max-lg:w-full top-1/2 left-1/2 -translate-x-2/3 -translate-y-1/2 max-lg:-translate-x-1/2 max-lg:px-10"> 
//     <h1 className="font-cinzel font-bold w-3/4 max-sm:hidden" style={{fontSize: height(), lineHeight: 1}}>RESTORATION DONE THE RIGHT WAY</h1>
//     <p className="text-4xl font-bold mt-4 w-4/5 max-md:block max-md:text-3xl">
//         - Twenty years experience<br/>
//         - More than 250 restorations<br/>
//         - 5200 Bottoms our specialty<br/>
//         - Mirror finishes
//     </p>
//     <button onClick={handleSeeMore} className="py-4 px-4 bg-slate-900 rounded text-white mt-10 text-2xl max-lg:hidden max-sm:block max-sm:px-8 max-sm:mb-6">See More...</button>
// </div>