import React from "react";
import GroupPic from "../media/missionfoto.jpeg";
import Wave2 from "./Wave2";

const Mission = () => {

    return (
        <div className="h-auto w-full">
            <div className="flex max-lg:flex-col align-middle justify-center pb-16 pt-48 gap-24 bg-slate-900 max-lg:gap-8 max-lg:pt-16">
                <img src={GroupPic} alt="5200 Bottom Coverage" className="border-8 border-owhite self-center w-1/4 h-full max-lg:w-full max-w-max" style={{maxWidth: "90%"}}/>
                <div className="w-1/3 text-left mt-6 max-lg:w-full max-lg:mt-2 max-lg:px-10" style={{maxHeight: "100lvh"}}>
                    <h2 className="text-6xl font-bebasneue text-owhite max-lg:text-5xl">Our Mission</h2>
                    <h2 className="font-semibold text-4xl mb-5 text-owhite font-bebasneue">______</h2>
                    <p className="text-3xl text-owhite max-lg:text-xl">
                        Here at Classic Boat Restorations we are passionate about classic mahogany powerboats.  We know you are too.  We believe in doing things right the first time, 
                        and take pride in our craftsmanship.  From master carpenters, to expert finishers…combined with using only the best marine products available...our goal is to 
                        provide a safe and beautiful classic you can enjoy for years to come with family and friends.  Let us restore a classic for you!
                    </p>
                </div>
            </div>
            <div className="bg-owhite">
                <Wave2/>
            </div>
        </div>
    )
}
export default Mission;

// {/* <h2 className="text-6xl font-bebasneue text-owhite max-lg:text-5xl">Our Mission</h2>
// <h2 className="font-semibold text-4xl mb-5 text-owhite font-bebasneue">______</h2>
// <p className="text-3xl text-owhite max-lg:text-xl">
// We love mahogany boats and are simply passionate about them. After more than twenty years, over one hundred restorations, 
// and more than 250 boat bottoms completed we know what we’re doing, and share the same pride in these boats when finished as their owners do.
// Classic Boat Restoration is your trusted partner. We don’t use the word partner lightly. A partner is a team player in a shared goal. 
// That means communicating every step of the way and making sure we are exceeding expectations.
// </p> */}