import React from "react";
// import logo from "../media/logo.png";

const NavFollow = () => {
    return (
        <div className="fixed top-0 w-screen h-44 bg-white z-10 max-sm:h-32">
            {/* <img src={logo} alt="Classic Boat Restorations" className="h-32 mt-6 ml-72 pl-56"/> */}
        </div>
    )
};

export default NavFollow;