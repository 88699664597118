import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { Link } from "react-router-dom";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';


export default function ActionAreaCard(boat) {
  return (
    <div className='border-4 border-slate-900 rounded-lg'>
    <Card sx={{ maxWidth: 345 }}>
    <Link to={`about/${boat.tagNum}`}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          image={boat.img}
          alt={boat.name}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div" className='text-center'>
            {boat.name}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Link>
    </Card>
    </div>
  );
}