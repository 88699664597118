import React from 'react';
import ImageGallery from 'react-image-gallery';
import { boats } from "../components/Boats";
import {
    useParams,
} from "react-router-dom";


const BoatMore = () => {
    const { key } = useParams()
    let selectedKey = parseInt(key);
    const selectedBoat = boats.find(boats => boats.tagNum === selectedKey)

    const createImgs = (boat) => {     // How we create organize the images from the boats array into a format that ImageGallery can use
        const images = [];  
            for (let i = 0; i < boat.img.length; i++) {   // basically for each image the boat has, we push it into the image galleries array
                images.push({original: boat.img[i], thumbnail: boat.img[i]})
            }
        return images;
    }   
    
    return (
        <div className='bg-owhite flex justify-center align-middle pt-72 max-sm:pt-60 overflow-hidden min-h-screen max-sm:pb-0 pb-32'>
            <div className='justify-center align-middle'>
                <h2 className="text-6xl font-bebasneue mb-16 text-slate-900 text-center max-sm:text-3xl max-sm:mb-8">- {selectedBoat.name} -</h2>
                <div className='max-sm:max-w-md px-6'>
                    <ImageGallery items={createImgs(selectedBoat)} showFullscreenButton={false} showPlayButton={false}/>
                </div>
            </div>
        </div>
    );
};

export default BoatMore;